.browse-container{
    margin-top: $s10;
    text-align: center;
    margin-bottom: $s10 * 2;
}
.browseButton{
    background-color: $main-color;
    color: $white-color;
    padding: $s1 $s9;
    border-radius: $s2;
    font-size: $h5;
    cursor: pointer;
    &:focus{
        border: 3px black solid;
    }
}
.background{
    height: 100vh;
    width: 100vw;
    z-index: -9999;
    position: absolute;
    left: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
    filter: blur($s10 * 2);
}