.visually-hidden {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    background-color: white;
    counter-reset: black;
}

.container{
    width: 89%;
    @include media-query(laptops){
        width: 76%;
    }
    @include media-query(four_k){
        width: 70%;
    }
    margin: 0 auto;
    &--no-center{
        margin: 0;
    }
}
@mixin right-aligned-container {
    width: 94.5%;
    margin-left: auto;
    margin-right: 0;
    @include media-query(laptops){
        width: 88%;
    }
    @include media-query(four_k){
        width: 85%;
    }
}
.right-aligned-container{
    @include right-aligned-container()
}
.small-container{
    width: 89%;
    @include media-query(laptops){
        width: 45%;
    }
    @include media-query(four_k){
        width: 40%;
    }
    margin: 0 auto;
    &--no-center{
        margin: 0;
    }
}

.medium-container{
    width: 89%;
    @include media-query(laptops){
        width: 65%;
    }
    @include media-query(four_k){
        width: 50%;
    }
    margin: 0 auto;
}

.big-container{
    width: 89%;
    @include media-query(laptops){
        width: 93%;
    }
    margin: 0 auto;
    &__inside-container{
        width: 81.5%;
        @include media-query(four_k){
            width: 76%;
        }
        margin: 0 auto;
    }
}

.full-container{
    width: 100%;
    padding: 0;
    margin: 0;
}

.two-columns-grid{
    display: grid;
    grid-template: auto / 1fr;
    @include media-query(laptops){
        grid-template: auto / 1fr 1fr;
    }
}
.web-dev-services-wrapper{
    margin-bottom: $s10;
}