.audio-player{
    display: none;
    background-color: white;
    border-radius: $s7;
    padding: $s2 $s5;
    @include media-query(tablets){
        padding: $s4 $s10;
        padding-left: $s10;
    }
    margin-top: $s8;
    &__grid{
        width: 100%;
        display: grid;
        grid-template: 1fr / 0.2fr 0.6fr 0.1fr 0.1fr;
        grid-gap: $s1;
        align-items: center;
        @include media-query(tablets){
            grid-template: 1fr / 0.05fr 0.85fr 0.1fr 0.1fr;
            grid-gap: $s2;
        }
    }
    .amplitude-playing{
        @include icon-style();
        background-image: get-icon("pause");
    }
    .amplitude-paused{
        @include icon-style();
        background-image: get-icon("play");
    }
    &__play-pause-button{
        font-size: 1.5rem;
    }
    &__slider{
        width: $s10 * 2;
        border-radius: $s1;
        @include media-query(tablets){
            width: auto;
        }
        background-color: $grey-color;
        -webkit-appearance: none;  /* Override default CSS styles */
        appearance: none;
        overflow: hidden;
        height: $s3;
    }
    &__slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: $s1/ 2;
        height: $s3;;
        background: $main-color;
        cursor: pointer;
        box-shadow: -80px 0 0 80px $main-color-dark;
    }
    &__slider::-moz-range-thumb {
        width: $s1 / 2;
        height: $s3;
        background: $main-color;
        cursor: pointer;
        border-radius: 0;
        border: none;
    }
    &__time{
        width: $s10 * 2.6;
    }
    &__sound{
        display: flex;
        flex-direction: column-reverse;
        position: relative;
    }
    &__sound-button{
        font-size: 1.5rem;
    }
    &__volume-slider{
        display: none;
        background-color: $grey-color-medium;
        border-radius: $s2 !important;
        transform-origin: top left;
        transform: rotate(-90deg);
        position: absolute;
        bottom: $s2;
        overflow: hidden;
    }
}

input[type="range"]::-moz-range-progress {
    background-color: $main-color-dark;
    height: 100%;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: $main-color-dark; 
}