$icons: (
	"open-in-new-tab": '<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%%COLOR%%" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>',
	"pause": '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 55"><rect width="17" height="54" rx="7" fill="%%COLOR%%"/><rect x="31" width="17" height="54" rx="7" fill="%%COLOR%%"/></svg>',
	"play": '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 55"><path d="M48 27.5L0 55V0l48 27.5z" fill="%%COLOR%%"/></svg>',
	"sound": '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 52"><path fill-rule="evenodd" clip-rule="evenodd" d="M46.151 5.8143L43.5587 9.697c4.739 3.85 7.7746 9.7207 7.7746 16.303 0 6.5847-3.0356 12.4553-7.7746 16.3053l2.5923 3.8804C52.1383 41.491 56 34.204 56 26c0-8.2017-3.8593-15.4887-9.849-20.1857zM40.95 13.603l-2.618 3.92c2.2587 2.1303 3.6867 5.1287 3.6867 8.4793 0 3.3507-1.428 6.3514-3.6867 8.4817l2.618 3.92c3.493-3.0007 5.7167-7.4387 5.7167-12.404 0-4.963-2.2214-9.401-5.7167-12.397zM28 .3333c-2.7463 0-3.577 1.596-3.577 1.596s-7.1423 7.847-12.061 11.3097c-.9053.5763-1.8993 1.0943-3.6587 1.0943H4.6667C2.0907 14.3333 0 16.424 0 19v14c0 2.576 2.0907 4.6667 4.6667 4.6667h4.0366c1.7594 0 2.7534.518 3.6564 1.0943 4.9186 3.4627 12.061 11.312 12.061 11.312s.833 1.5937 3.5793 1.5937c2.576 0 4.6667-2.0884 4.6667-4.6667V5C32.6667 2.4217 30.576.3333 28 .3333z" fill="%%COLOR%%"/></svg>'
);

$data-svg-prefix: 'data:image/svg+xml;utf-8,';

@function str-replace( $string, $search, $replace ) {
	$index: str-index( $string, $search );
	@if $index {
		@return str-slice( $string, 1, $index - 1 ) + $replace + str_replace( str-slice( $string, $index + str-length( $search ) ), $search, $replace );
	}
	@return $string;
}

@function get-icon( $icon, $color: $main-color ) {
	@if 'color' != type-of( $color ) {
		@warn 'The requested color - "' + $color + '" - was not recognized as a Sass color value.';
		@return null;
	}
	@if map-has-key( $icons, $icon ) {
		$icon:        map-get( $icons, $icon );
		$placeholder: '%%COLOR%%';
		$data-uri:    str-replace( url( $data-svg-prefix + $icon ), $placeholder, $color );
		@return str-replace( $data-uri, '#', '%23' );
	}
	@warn 'The requested icon - "' + $icon + '" - is not defined in the $icons map.';
	@return null;
}

@mixin icon-style() {
	height: 1em;
    width: 1em;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    display: inline-block;
}
.icon{
    @include icon-style();
    @each $name, $value in $icons{
        &--#{$name}{
            background-image: get-icon($name);
		}
		&--#{$name}-white{
            background-image: get-icon($name, #fff);
		}
	}
}