html, body{
    min-height: 100vh;
}
html{
    font-family: 'Montserrat', sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 1.04rem;
    @include media-query(laptops){
        font-size: 1.1rem;
    }
    @include media-query(desktops){
        font-size: 1.15rem;
    }
    @include media-query(large){
        font-size: 1.8rem;
    }
    @include media-query(four_k){
        font-size: 2.5rem;
    }
}
body{
    line-height: 1.5;
    letter-spacing: 0.02rem;
    color: $black-color;
    display: flex;
    flex-direction: column;
}
main{
    flex: 1;
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a {
    color: $link-color;
    text-decoration: none;
    background-color: transparent;
    &:hover {
        color: $link-hover-color;
        text-decoration: underline;
    }
}
a[target="_blank"]:after {
    content: get-icon("open-in-new-tab", $link-color);
    @include icon-style();
    margin-left: 0.4em;
}