$white-color: #fff;
$black-color: #090909;

$main-color: #9E5B53;
$main-color-dark: #97554D;
$main-color-really-dark: #7C463F;

$secondary-color: #fff;

$grey-color: #F4F4F4;
$grey-color-light: #FEFEFE;
$grey-color-medium: #9b9b9b;
$grey-color-dark: #3D3D3D;

$sub-heading-color: #595959;

$link-color: #1a0dab;
$link-hover-color: $link-color;

$header-background-color: $white-color;
$header-color: $black-color;

$purple-color: #360747;
$orange-color-dark: #946807;

$s1: 0.8rem;
$s2: 1.2rem;
$s3: 1.4rem;
$s4: 1.6rem;
$s5: 1.8rem;
$s6: 2rem;
$s7: 2.2rem;
$s8: 2.4rem;
$s9: 2.6rem;
$s10: 2.8rem;
$s11: 3rem;