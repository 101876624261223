$h1: 2.5rem;
$h2: 2rem;
$h3: 1.75rem;
$h4: 1.5rem;
$h5: 1.25rem;
$h6: 1rem;

h1, .h1{
    font-size: $h1;
}

h2, .h2{
    font-size: $h2;
}

h3, .h3{
    font-size: $h3;
}

h4, .h4{
    font-size: $h4;
}

h5, .h5{
    font-size: $h5;
}

h6, .h6{
    font-size: $h6;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    margin-bottom: .5rem;
    line-height: 1.2;
    color: $black-color;
}

h1, .h1{
    font-weight: bold;
    color: $main-color;
}

h2, .h2{
    font-weight: 500;
}

.text{
    font-size: 1.05rem;
    font-weight: 300;
}

.app-title{
    font-size: $h1 * 1.8;
    text-align: center;
    margin-top: $s10 * 1.5;
}

.secondary-title{
    font-size: $h3;
    text-align: center;
    color: $grey-color-dark;
    font-weight: 300;
    margin-top: $s1;
    max-width: 90%;
    margin: auto;
}