.main-container{
    margin-top: $s9;
    background-color: $grey-color;
    padding: $s6;
    border-radius: $s4;
    margin-bottom: $s10;
    display: flex;
    align-items: center;
    justify-content: center;
    &__container{
        @include media-query(laptops){
            width: 50%;
        }
    }
    &__title{
        font-weight: normal;
        text-align: center;
        font-size: $h1 / 1.25;
        @include media-query(tablets){
            font-size: $h1 / 1.1;
        }
    }
    &__file-name{
        font-weight: normal;
        font-size: $h6;
        text-align: center;
        margin-top: $s1;
        display: none;
    }
}